import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarData, SnackbarBaseComponent } from './snackbar-base/snackbar-base.component';
import {ApiError} from "@models/error.model";
import {getApiErrorMessage, getServerErrorMessage} from "../../../helpers/errors.helper";

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  constructor(private snackbar: MatSnackBar) {}

  show(message: string, options?: Omit<SnackbarData, 'message'>) {
    return this.snackbar.openFromComponent(SnackbarBaseComponent, {
      panelClass: `ui-snackbar-default`,
      data: { message, panelClass: 'is-message', ...options } as SnackbarData,
      duration: 3200,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
    });
  }

  error(message: string, options?: Omit<SnackbarData, 'message'>) {
    return this.snackbar.openFromComponent(SnackbarBaseComponent, {
      panelClass: `ui-snackbar-default`,
      data: { message, panelClass: 'is-error', ...options } as SnackbarData,
      duration: 3200,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
    });
  }

  showServerError(errorStatus: number, apiError?: ApiError) {
    this.error(`${errorStatus === 400 && apiError ? getApiErrorMessage(apiError) : getServerErrorMessage(errorStatus)}${errorStatus !== 0 ? ` (${errorStatus}) ` : ''}`);
  }
}
