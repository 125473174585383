import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IconsModule } from './icons.module';
import { MatDialogModule } from '@angular/material/dialog';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ApiInterceptor, ErrorInterceptor, TokenInterceptor } from './interceptors';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { DATEFNS_PERSIAN_DATE_FORMATS, DatefnsJalaliDateAdapter, } from './shared/material.persian-date.adapter';
import { faIR } from 'date-fns/esm/locale';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { Subject } from 'rxjs';
import { DecimalPipe } from '@angular/common';

export class CustomPaginator implements MatPaginatorIntl {
  changes = new Subject<void>();
  firstPageLabel = `صفحه اول`;
  itemsPerPageLabel = `تعداد آیتم ها در هر صفحه`;
  lastPageLabel = `صفحه آخر`;
  nextPageLabel = 'صفحه بعد';
  previousPageLabel = 'صفحه قبل';
  getRangeLabel(page: number, pageSize: number, length: number): string {
    const start = page * pageSize;
    const end = Math.min(start + pageSize, length);
    if (length === 0) {
      return ``;
    }
    return `نمایش ${start + 1} تا ${end} از ${length}`;
  }
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    IconsModule,
    MatDialogModule,
    MatSnackBarModule,
    HttpClientModule,
  ],

  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    { provide: MAT_DATE_LOCALE, useValue: faIR },
    { provide: MAT_DATE_FORMATS, useValue: DATEFNS_PERSIAN_DATE_FORMATS },
    { provide: DateAdapter, useClass: DatefnsJalaliDateAdapter, deps: [MAT_DATE_LOCALE] },
    {provide: MatPaginatorIntl, useClass: CustomPaginator},
    DecimalPipe  //For using DecimalPipe in Services with provideIn:root
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
