import { Injectable } from '@angular/core';
import { createStore, withProps } from '@ngneat/elf';
import { persistState } from '@ngneat/elf-persist-state';
import { HttpClient } from '@angular/common/http';
import { EMPTY, concatMap, tap } from 'rxjs';
import { Auth, LoginModel } from '@models/auth.model';
import { StorageEngine } from '../engines';
import { AppStore } from '@states/app.store';
import { User } from '@models/user.model';
import { UserApiService } from '@services/user-api.service';

const AUTH_KEY = 'auth';

interface AuthStoreProps {
  data?: Auth;
  user?: User;
}
export const authStore = createStore({ name: AUTH_KEY }, withProps<AuthStoreProps>({}));
persistState(authStore, {
  key: AUTH_KEY,
  storage: StorageEngine,
});

@Injectable({
  providedIn: 'root',
})
export class AuthStore {
  store = authStore;

  constructor(private httpClient: HttpClient, private app: AppStore, private userApiService: UserApiService) {}

  login(model: LoginModel, rememberMe: boolean) {
    this.app.setRememberMe(rememberMe);
    return this.httpClient.post<Auth>(`/User/Login`, model).pipe(
      tap((result) => {
        this.store.update((state) => ({
          ...state,
          data: {
            ...result,
          },
        }));
      }),
      concatMap(() => this.refreshUser())
    );
  }

  logout() {
    this.store.reset();
  }

  refreshUser() {
    return this.store.state.data?.id
      ? this.userApiService.get(this.store.state.data.id).pipe(
          tap((result) => {
            this.store.update((state) => ({
              ...state,
              user: result.userModel,
            }));
          }),
        )
      : EMPTY;
  }
}
