import {AbstractControl} from "@angular/forms";

export function getControlErrorMessage(control: AbstractControl, customMessage?: string) {
  if (control.status !== 'INVALID') return undefined;
  const errorKeys = Object.keys(control.errors ?? {});
  const firstKey = errorKeys.length > 0 ? errorKeys[0] : undefined;
  switch (firstKey) {
    case 'required':
      return 'این فیلد الزامی می باشد.';
    case 'email':
      return 'این ایمیل درست نیست.';
    case 'codeMelli':
      return 'کد ملی نامعتبر است.';
    case 'unmatched':
      return 'مقدار این فیلد مطابقت ندارد.';
    case undefined:
      return undefined;
    default:
      return !customMessage ? 'مقدار این فیلد اشتباه میباشد.' : customMessage;
  }
}


export function getServerErrorMessage(status: number) {
  switch (status) {
    case 0:
      return 'اشکال در اتصال، اینترنت خود را چک کنید.';
    case 401:
      return 'نشست شما منقضی شده، لطفا دوباره لاگین کنید.';
    case 500:
      return 'اشکالی در سرور رخ داده.'
    default:
      return 'ارور.'
  }
}
export function getApiErrorMessage(error: {code: number; message: string}) {
  switch (error.code) {
    default:
      return error.message
  }
}
