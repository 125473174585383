export const DATEFNS_PERSIAN_DATE_FORMATS = {
  parse: {
    dateInput: 'yyyy/MM/dd'
  },
  display: {
    dateInput: 'yyyy/MM/dd',
    monthYearLabel: 'yyyy MMMM',
    dateA11yLabel: 'yyyy/MM/dd',
    monthYearA11yLabel: 'yyyy MMMM'
  }
};

import { Inject, Injectable, Optional } from "@angular/core";
import { DateAdapter, MAT_DATE_LOCALE } from "@angular/material/core";
import {
  addDays,
  addMonths,
  addYears,
  format,
  getDate,
  getDay,
  set,
  getDaysInMonth,
  getMonth,
  getYear,
  Locale,
  parse,
  parseISO,
  setDay,
  setMonth,
  toDate
} from "date-fns-jalali";
import faIR from "date-fns-jalali/locale/fa-jalali-IR";

function range(start: number, end: number): number[] {
  const arr: number[] = [];
  for (let i = start; i <= end; i++) {
    arr.push(i);
  }

  return arr;
}

@Injectable()
export class DatefnsJalaliDateAdapter extends DateAdapter<Date> {
  private _dateFnsLocale?: Locale;
  private getLocale = (localeCodeOrLocale: string | Locale): Locale => {
    return faIR;
  };

  constructor(
    @Optional() @Inject(MAT_DATE_LOCALE) dateLocale: string
  ) {
    super();

    try {
      this.setLocale(dateLocale || faIR);
    } catch (err) {
      this.setLocale(faIR);
    }
  }

  override setLocale(locale: string | Locale) {
    if (!locale) {
      throw new Error(
        "setLocale should be called with the string locale code or date-fns Locale object"
      );
    }
    this._dateFnsLocale = this.getLocale(locale);
    super.setLocale(locale);
  }

  addCalendarDays(date: Date, days: number): Date {
    return addDays(date, days);
  }

  addCalendarMonths(date: Date, months: number): Date {
    return addMonths(date, months);
  }

  addCalendarYears(date: Date, years: number): Date {
    return addYears(date, years);
  }

  clone(date: Date): Date {
    return toDate(date);
  }

  createDate(year: number, month: number, date: number): Date {
    // Check for invalid month and date (except upper bound on date which we have to check after
    // creating the Date).
    if (month < 0 || month > 11) {
      throw Error(
        `Invalid month index "${month}". Month index has to be between 0 and 11.`
      );
    }

    if (date < 1) {
      throw Error(`Invalid date "${date}". Date has to be greater than 0.`);
    }

    const result = this._createDateWithOverflow(year, month, date);
    // Check that the date wasn't above the upper bound for the month, causing the month to overflow
    if (getMonth(result) !== month) {
      throw Error(`Invalid date "${date}" for month with index "${month}".`);
    }

    return result;
  }

  override deserialize(value: any): Date | null {
    if (value) {
      if (typeof value === "string") {
        return parseISO(value);
      }
      if (typeof value === "number") {
        return toDate(value);
      }
      if (value instanceof Date) {
        return this.clone(value as Date);
      }
      return null;
    }
    return null;
  }

  format(date: Date, displayFormat: string): string {
    return format(date, displayFormat, { locale: this._dateFnsLocale });
  }

  getDate(date: Date): number {
    return getDate(date);
  }

  getDateNames(): string[] {
    return range(1, 31).map(day => String(day));
  }

  getDayOfWeek(date: Date): number {
    return getDay(date);
  }

  getDayOfWeekNames(style: "long" | "short" | "narrow"): string[] {
    const map = {
      long: "EEEE",
      short: "EEE",
      narrow: "EEEEE"
    };

    const formatStr = map[style];
    const date = new Date();

    return range(0, 6).map(month =>
      format(setDay(date, month), formatStr, {
        locale: this._dateFnsLocale
      })
    );
  }

  getFirstDayOfWeek(): number {
    return this._dateFnsLocale?.options?.weekStartsOn ?? 0;
  }

  getMonth(date: Date): number {
    return getMonth(date);
  }

  getMonthNames(style: "long" | "short" | "narrow"): string[] {
    const map = {
      long: "LLLL",
      short: "LLL",
      narrow: "LLLLL"
    };

    const formatStr = map[style];
    const date = new Date();

    return range(0, 11).map(month =>
      format(setMonth(date, month), 'LLLL', {
        locale: this._dateFnsLocale
      })
    );
  }

  getNumDaysInMonth(date: Date): number {
    return getDaysInMonth(date);
  }

  getYear(date: Date): number {
    return getYear(date);
  }

  getYearName(date: Date): string {
    return format(date, "yyyy", {
      locale: this._dateFnsLocale
    });
  }

  invalid(): Date {
    return new Date(NaN);
  }

  isDateInstance(obj: any): boolean {
    return obj instanceof Date;
  }

  isValid(date: Date): boolean {
    return date instanceof Date && !isNaN(date.getTime());
  }

  parse(value: any, parseFormat: any): Date | null {
    if (value) {
      if (typeof value === "string") {
        return parse(value.trim(), parseFormat, new Date(), {
          locale: this._dateFnsLocale
        });
      }
      if (typeof value === "number") {
        return toDate(value);
      }
      if (value instanceof Date) {
        return this.clone(value as Date);
      }
      return null;
    }
    return null;
  }

  toIso8601(date: Date): string {
    return date.toISOString();
  }

  today(): Date {
    return new Date();
  }

  private _createDateWithOverflow(year: number, month: number, date: number) {
    return set(new Date(), { year, month, date });
  }
}