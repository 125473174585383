import { Injectable } from '@angular/core';
import { User, UserData, UserModel, UserUpdateModel } from '@models/user.model';
import { TableApiService, TableFilter } from './table-api.service';
import { HttpClient } from '@angular/common/http';
import { ExportOutput } from '@features/table/table.component';

@Injectable({
  providedIn: 'root',
})
export class UserApiService {
  constructor(private httpClient: HttpClient, private tableApiService: TableApiService) {}

  getListForTable(filters: TableFilter[] = []) {
    return this.tableApiService.createGetRequest<User>('/User/GetAllUsers', (x) => ({data: x.users, pagination: x.pagination}), filters);
  }

  get(id: string) {
    return this.httpClient.get<{userModel: User}>(`/User/GetUser?UserId=${id}`);
  }

  getAll(filters: TableFilter[] = [], sort?: ExportOutput) {
    return this.httpClient.get<UserData>(`/User/GetAllUsers?CurrentPage=0&${this.tableApiService.getFilteredSortedUrl(filters, sort)}`);
  }

  create(model: UserModel) {
    return this.httpClient.post('/User/UserCreation', model);
  }

  update(model: UserUpdateModel) {
    return this.httpClient.put('/User/UpdateUser', model);
  }
}
