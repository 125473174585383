export const environment = {
  production: false,
  apiBaseUrl: 'https://jalalabad.adad.ws',
  mqttSettings: {
    username: 'admin',
    password: 'admin',
    reconnectPeriod: 4000,
    url: 'ws://104.238.170.13',
    port: '8083',
  },
};
