import { Injectable } from '@angular/core';
import { createStore, withProps } from '@ngneat/elf';
import {localStorageStrategy, persistState} from '@ngneat/elf-persist-state';

const APP_KEY = 'app';

interface AppStoreProps {
  rememberMe?: boolean;
}
export const appStore = createStore({ name: APP_KEY }, withProps<AppStoreProps>({}));
persistState(appStore, {
  key: APP_KEY,
  storage: localStorageStrategy,
});

@Injectable({
  providedIn: 'root',
})
export class AppStore {
  store = appStore;

  rememberMe() {
    return this.store.getValue().rememberMe ?? false
  }

  setRememberMe(value: boolean) {
    this.store.update((s) => ({
      ...s,
      rememberMe: value
    }));
  }
}
