import { NgModule } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';

@NgModule({
  imports: [MatIconModule],
  exports: [MatIconModule],
})
export class IconsModule {
  icons = [
    'plus',
    'edit',
    'clear',
    'error',
    'search',
    'calendar',
    'leading',
    'ai',
    'bag',
    'company',
    'document',
    'edit',
    'edit-outline',
    'id',
    'paper',
    'paper-draw',
    'pie-chart',
    'station',
    'truck',
    'truck-alt',
    'warn',
    'weight',
    'nav',
    'sync',
    'settings',
    'dropdown',
    'tabicon',
    'edit-row',
    'close',
    'info',
    'list',
    'police',
    'check',
    'paper-close',
    'paper-edit',
    'tag',
    'kg',
    'building',
    'weight-scale',
    'time',
    'chevron-left',
    'chevron-right',
    'close-rounded',
    'cargo',
    'paper-detail'
  ];
  constructor(private sanitizer: DomSanitizer, private iconRegistry: MatIconRegistry) {
    for (const icon of this.icons) {
      iconRegistry.addSvgIcon(icon, sanitizer.bypassSecurityTrustResourceUrl(`./assets/icons/${icon}.svg`));
    }
  }
}
