import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Pagination } from '@models/pagination.model';
import { ExportOutput } from '@features/table/table.component';

export interface TableOptions {
  currentPage: number;
  pageItems: number;
  sortItem: string;
  ascDesc: TableSort;
}

export type TableSort = 'IsAscending' | 'IsDescending';

export interface TableFilter {
  key: string;
  value: string;
}

export interface TablePaginatedResponse<T> {
  data: T[];
  pagination: Pagination;
}

export const DEFAULT_OPTIONS: TableOptions = { currentPage: 1, pageItems: 10, sortItem: 'Id', ascDesc: 'IsAscending' };

@Injectable({
  providedIn: 'root',
})
export class TableApiService {
  constructor(private http: HttpClient) {}

  createGetRequest<T>(
    url: string,
    mapFn?: (x: any) => TablePaginatedResponse<T>,
    filters?: TableFilter[],
    noPagination?: boolean
  ): (options?: TableOptions) => Observable<TablePaginatedResponse<T> | T[]> {
    return (options) => this.tableFetchRequest(url, mapFn, options, filters, noPagination);
  }

  private tableFetchRequest<T>(
    url: string,
    mapFn?: (x: any) => TablePaginatedResponse<T>,
    options: TableOptions = DEFAULT_OPTIONS,
    filters?: TableFilter[],
    noPagination?: boolean
  ): Observable<TablePaginatedResponse<T> | T[]> {
    const requestUrl = this.optionsToUrl(url, options, filters, noPagination);
    return this.http.get<TablePaginatedResponse<T> | T[]>(requestUrl).pipe(map(mapFn ?? ((x) => x)));
  }

  optionsToUrl(url: string, options: TableOptions, filters?: TableFilter[], noPagination?: boolean) {
    return `${url}?CurrentPage=${noPagination ? '0' : `${options.currentPage}&PageItems=${options.pageItems}`}&SortItem=${options.sortItem}&AscDesc=${options.ascDesc}${this.getUrlFilters(filters)}`
  }

  getUrlFilters(filters?: TableFilter[]) {
    let filtersStr = '';
    filters?.map((filter) => {
      filtersStr += `&Filters.${filter.key}=${filter.value}`;
    });
    return filtersStr;
  }

  getFilteredSortedUrl(filters?: TableFilter[], sort?: ExportOutput) {
    return `SortItem=${sort?.sortItem || DEFAULT_OPTIONS.sortItem}&AscDesc=${sort?.ascDesc || DEFAULT_OPTIONS.ascDesc}${this.getUrlFilters(filters)}`;
  }
}
