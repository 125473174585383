import { Pagination } from './pagination.model';
import { StatusType } from './utility.interface';

export interface User {
  nationalCode: string;
  personnelId: string;
  firstName: string;
  lastName: string;
  status: StatusType;
  id: string;
  roles: {roleID: UserRole}[]
}

export interface UserData {
  users: User[];
  pagination: Pagination;
}

export interface UserModel {
  nationalCode: string;
  password: string;
  personnelID: string;
  firstName: string;
  lastName: string;
  role: number;
  status: StatusType;
}

export interface UserUpdateModel extends UserModel {
  userId: string;
}

export enum UserRole {
  Admin = '1',
  Supervisor = '2',
  Operator = '3',
  Security = '4',
}
