import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { authGuard } from '@guards/auth.guard';
import { permissionGuard } from '@guards/permission.guard';

const routes: Routes = [
  { path: '', redirectTo: 'data/users', pathMatch: 'full' },
  {
    path: 'login',
    loadComponent: () => import('./pages/login/login.component').then((m) => m.LoginComponent),
  },
  {
    path: '',
    loadComponent: () =>
      import('./components/layout/main-layout/main-layout.component').then((m) => m.MainLayoutComponent),
    canActivate: [authGuard, permissionGuard],
    canActivateChild: [authGuard, permissionGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'data',
      },
      {
        path: 'data',
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: 'users',
          },
          {
            path: 'users',
            loadChildren: () => import('./pages/users/users.component').then((m) => m.Route),
          },
          {
            path: 'drivers',
            loadChildren: () => import('./pages/drivers/drivers.component').then((m) => m.Route),
          },
          {
            path: 'items',
            loadChildren: () => import('./pages/items/items.component').then((m) => m.Route),
          },
          {
            path: 'weighing-machines',
            loadChildren: () => import('./pages/weighing-machines/weighing-machines.component').then((m) => m.Route),
          },
          {
            path: 'shipment-orders',
            loadChildren: () => import('./pages/shipment-orders/shipment-orders.component').then((m) => m.Route),
          },
          {
            path: 'trucking-companies',
            loadChildren: () => import('./pages/trucking-companies/trucking-companies.component').then((m) => m.Route),
          },
          {
            path: 'weighing-stations',
            loadChildren: () => import('./pages/weighing-stations/weighing-stations.component').then((m) => m.Route),
          },
          {
            path: 'vehicles',
            loadChildren: () => import('./pages/vehicles/vehicles.component').then((m) => m.Route),
          },
        ],
      },
      {
        path: 'security',
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: 'live',
          },
          {
            path: 'live',
            loadChildren: () =>
              import('./pages/live-current-vehicles/live-current-vehicles.component').then((m) => m.Route),
          },
        ],
      },
      {
        path: 'bills',
        loadChildren: () =>
        import('./pages/bills/bills.component').then((m) => m.Route),
      },
      {
        path: 'other-item-bills',
        loadChildren: () =>
        import('./pages/other-item-bills/other-item-bills.component').then((m) => m.Route),
      },
      {
        path: 'settings',
        loadChildren: () =>
        import('./pages/settings/settings.component').then((m) => m.Route),
      },
    ],
  },
  {
    path: 'test-zone',
    loadComponent: () => import('./test-zone/test-zone.component').then((m) => m.TestZoneComponent),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
