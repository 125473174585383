import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthApiService } from '@services/auth-api.service';
import { tap } from 'rxjs';

export const authGuard: CanActivateFn = () => {
  const router = inject(Router);
  return inject(AuthApiService).isLoggedIn().pipe(
    tap((loggedIn) => {
      return loggedIn ? true : router.navigate(['/login']);
    }),
  );
};
