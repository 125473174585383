import {localStorageStrategy, sessionStorageStrategy, StateStorage} from "@ngneat/elf-persist-state";
import {appStore} from "@states/app.store";

function storage() {
  const rememberMe = appStore.getValue().rememberMe;
  if (rememberMe) {
    return localStorageStrategy;
  } else {
    return sessionStorageStrategy;
  }
}

export const StorageEngine: StateStorage = {
  getItem: (key) => {
    return storage().getItem(key)
  },
  setItem: (key, value) => {
    return storage().setItem(key, value)
  },
  removeItem: (key) => {
    return storage().removeItem(key)
  }
}
