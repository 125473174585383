import { Injectable, signal } from '@angular/core';
import { AuthApiService } from './auth-api.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { BehaviorSubject, take } from 'rxjs';
import { UserRole } from '@models/user.model';
import { Router } from '@angular/router';

export type ActionTypes = 'create' | 'update' | 'delete' | 'print';

interface AppRoute {
  route: string;
  allowedActions: ActionTypes[];
}

export interface UserPermission {
  role: UserRole;
  allowedRoutes: AppRoute[] | 'all';
}

const ALL_ACTIONS: ActionTypes[] = ['create', 'update', 'delete', 'print'];

const PERMISSIONS: UserPermission[] = [
  {
    role: UserRole.Admin,
    allowedRoutes: 'all',
  },
  {
    role: UserRole.Supervisor,
    allowedRoutes: [
      {
        route: 'bills',
        allowedActions: ['print', 'update'],
      },
      {
        route: 'security',
        allowedActions: [],
      },
      {
        route: 'other-item-bills',
        allowedActions: ['print'],
      },
    ],
  },
  {
    role: UserRole.Security,
    allowedRoutes: [
      {
        route: 'security',
        allowedActions: ['update'],
      },
    ],
  },
  {
    role: UserRole.Operator,
    allowedRoutes: [],
  },
];

@Injectable({
  providedIn: 'root',
})
export class RoleService {
  userPermission = signal<UserPermission | undefined>(undefined);
  allowdRoutes$ = new BehaviorSubject<'all' | string[]>([]);

  constructor(private authApiService: AuthApiService, private router: Router) {
    this.refreshUser();
    this.authApiService.state$.pipe(takeUntilDestroyed()).subscribe((state) => {
      const userRole = state.user?.roles?.length ? state.user.roles[0].roleID : undefined;
      const permission = PERMISSIONS.find((p) => p.role === userRole);
      this.userPermission.set(permission);
      const alloewdRoutes =
        permission?.allowedRoutes === 'all'
          ? permission.allowedRoutes
          : permission?.allowedRoutes.map?.((x) => x.route) || [];
      this.allowdRoutes$.next(alloewdRoutes);
      if (alloewdRoutes instanceof Array && !alloewdRoutes.includes(this.router.url)) {
        alloewdRoutes.length ? this.router.navigate([`/${alloewdRoutes[0]}`]) : this.router.navigate([`/login`]);
      }
    });
  }

  hasVisitPermission(route: string) {
    const permissions = this.userPermission();
    return permissions?.allowedRoutes === 'all' || !!this.getRoutePermission(route, permissions?.allowedRoutes || []);
  }

  getAllowedActions(route: string): ActionTypes[] {
    const permissions = this.userPermission();
    if (permissions?.allowedRoutes === 'all') {
      return ALL_ACTIONS;
    } else {
      const currentRoute = this.getRoutePermission(route, permissions?.allowedRoutes || []);
      if (!currentRoute) {
        return [];
      } else {
        return currentRoute.allowedActions;
      }
    }
  }

  private getRoutePermission(currentRoute: string, alloewdRoutes: AppRoute[]) {
    return alloewdRoutes.find((x) => currentRoute.startsWith(`/${x.route}`));
  }

  private refreshUser() {
    this.authApiService.refreshUser().pipe(take(1)).subscribe();
  }
}
