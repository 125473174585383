import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  HostBinding,
  ChangeDetectionStrategy, Output, EventEmitter
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import {NgIf, NgStyle} from '@angular/common';

@Component({
  selector: 'ui-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
  standalone: true,
  imports: [MatIconModule, NgIf, NgStyle],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconComponent implements OnChanges {
  @Input() icon?: string;
  @Input() disabled = false;
  @Input() size = '1.5rem';
  @Input() filterAnimation = false;
  @Output() onClick = new EventEmitter<MouseEvent>();
  @HostBinding('class.pointer-events-none') pointerEventsNone = false;

  ngOnChanges(changes: SimpleChanges) {
    if (changes['disabled']) {
      this.pointerEventsNone = this.disabled;
    }
  }

  onClickEvent(e: MouseEvent) {
    if (this.disabled) return;

    this.onClick.emit(e);
  }
}
