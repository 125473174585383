import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { SnackbarService } from '@features/snackbar';
import { AuthApiService } from '@services/auth-api.service';
import { RoleService } from '@services/role.service';

export const permissionGuard: CanActivateFn = (route, state) => {
  const roleService = inject(RoleService);
  const router = inject(Router);
  const snackbarService = inject(SnackbarService);
  if (roleService.hasVisitPermission(state.url)) {
    return true;
  } else {
    const permissions = roleService.userPermission();
    const allowedRoute =
      permissions?.allowedRoutes instanceof Array && permissions?.allowedRoutes.length
        ? permissions?.allowedRoutes[0]
        : undefined;
    if (allowedRoute) {
      return router.navigate([`/${allowedRoute.route}`]);
    } else {
      snackbarService.error('شما به پنل ادمین دسترسی ندارید.');
      inject(AuthApiService).logout();
      return false;
    }
  }
};
