import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import { AuthApiService } from '@services/auth-api.service';
import {SnackbarService} from "@features/snackbar";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private authApiService: AuthApiService, private snackbar: SnackbarService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((e: any) => {
        if (e.status === 401) {
          this.authApiService.logout();
        } else {
          console.error('Server Error');
        }
        this.snackbar.showServerError(e.status, e.error?.error)
        return throwError(() => e);
      }),
    );
  }
}
