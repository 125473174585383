import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LoginModel } from '@models/auth.model';
import { map, take, tap } from 'rxjs';
import { AuthStore } from '../states/auth.store';
import { select } from '@ngneat/elf';
import { UserRole } from '@models/user.model';

@Injectable({
  providedIn: 'root',
})
export class AuthApiService {
  constructor(private router: Router, private authStore: AuthStore) {}

  get state$() {
    return this.authStore.store.pipe(select((state) => state));
  }

  get state() {
    return this.authStore.store.state;
  }

  isLoggedIn() {
    return this.state$.pipe(map((x) => !!x?.data?.jwt));
  }

  login(model: LoginModel, rememberMe: boolean) {
    return this.authStore.login(model, rememberMe).pipe(
      take(1),
      tap(() => {
        this.router.navigate(['/']);
      }),
    );
  }

  logout() {
    this.authStore.logout();
    this.router.navigate(['/login']);
  }

  refreshUser() {
    return this.authStore.refreshUser();
  }

  isAdmin() {
    return !!this.state.user?.roles?.find((role) => role.roleID === UserRole.Admin);
  }
}
